















































































@import '@design';

.home-hero {
  position: relative;
  min-height: 40vh;
  @media only screen and (min-width: 960px) {
    > .row .container {
      max-width: 1440px;
      min-height: 50vh;
    }
  }
}

.cta-wrap {
  max-width: 400px !important;
}

.home-hero {
  .block-inner-container {
    display: flex;
    align-items: center;
  }
  .section-content {
    padding: 0;
  }
}
